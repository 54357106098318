import React, { FC } from "react";
import { graphql } from "gatsby";

import { PeoplePageQuery } from "lib/cms";

import PageWrapper from "components/PageWrapper";
import NotFoundPage from "./404";
import FullWidth from "components/FullWidth";
import MaxWidth from "components/MaxWidth";
import PortableText from "components/PortableText";
import MetaData from "components/MetaData";

interface Props {
  data: PeoplePageQuery;
  location: Location;
}

const PeoplePage: FC<Props> = ({ data: { page } }) => {
  return page ? (
    <PageWrapper backgroundColor={page.backgroundColor}>
      <MetaData title="People" />
      <FullWidth>
        {page.people.map(({ _key, backgroundColor, person }) => (
          <MaxWidth backgroundColor={backgroundColor} key={_key}>
            <h2 className="text-medium md:text-base font-bold mb-2.5 md:mb-9">
              {person.name}
            </h2>
            <PortableText blocks={person.biography} className="md:text-small" />
          </MaxWidth>
        ))}
      </FullWidth>
    </PageWrapper>
  ) : (
    <NotFoundPage />
  );
};

export const query = graphql`
  query PeoplePage {
    page: sanityPeople {
      backgroundColor
      people {
        _key
        backgroundColor
        person {
          name
          biography: _rawBiography
        }
      }
    }
  }
`;

export default PeoplePage;
