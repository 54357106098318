import React, { FC } from "react";

interface Props {
  backgroundColor: string | null;
}

const PageWrapper: FC<Props> = ({ backgroundColor, children }) => {
  return (
    <article
      className={`bg-${backgroundColor ||
        "transparent"} px-2 md:px-10 pt-8.1 pb-2.5 md:py-9 flex-grow text-mobile-base md:text-base`}
    >
      {children}
    </article>
  );
};

export default PageWrapper;
