import { TailwindColor } from "lib/cms";
import React, { FC } from "react";

interface Props {
  backgroundColor?: TailwindColor | null;
}

const MaxWidth: FC<Props> = ({ children, backgroundColor }) => {
  return (
    <div
      className={`bg-${backgroundColor ||
        "transparent"} px-2 md:px-10 py-2.5 md:py-9`}
    >
      {children}
    </div>
  );
};

export default MaxWidth;
